import React from 'react';
import LoginRoute from './Components/Routes/LoginRoute';
import MainRoute from './Components/Routes/MainRoute';
import './App.css';
import 'moment/locale/pt-br';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function App() {
  if(!sessionStorage.getItem('gestao_userId')){
    return(
      <LoginRoute />
    );
  }else{
    return(
      <MainRoute />
    )
  }
}