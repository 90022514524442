import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { loadClientPanel } from '../Api/Api';

export default function InfoModal({ show, handleClose, client_ID}){

    const [ client, setClient ] = useState(null);
    var clientID = client_ID;

    const loadClientData = async (clientID) => {
        if (clientID) {
            const res = await loadClientPanel(clientID);
            if(res){
                setClient(res.data);
            }
        }
    }

    useEffect(()=>{
        loadClientData(clientID);
    }, [clientID]);

    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Informações da Unidade
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className='col-12'>
                            <div className="form-floating">
                            </div>
                            <div className="form-floating">
                                <input className="form-control" placeholder="Nome" aria-label='Nome' value={client?.info_name} readOnly />
                                <label>Nome</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-8'>
                            <div className="form-floating">
                            </div>
                            <div className="form-floating">
                                <input className="form-control" placeholder="Endereço" aria-label='Endereço' value={client?.address_street} readOnly />
                                <label>Endereço</label>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className="form-floating">
                            </div>
                            <div className="form-floating">
                                <input className="form-control" placeholder="numero" aria-label='numero' value={client?.address_number} readOnly />
                                <label>Nº</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-12'>
                            <div className="form-floating">
                            </div>
                            <div className="form-floating">
                                <input className="form-control" placeholder="E-mail" aria-label='E-mail' value={client?.info_email} readOnly />
                                <label>E-mail</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-12'>
                            <div className="form-floating">
                            </div>
                            <div className="form-floating">
                                <input className="form-control" placeholder="Telefone" aria-label='Telefone' value={client?.info_phone} readOnly />
                                <label>Telefone</label>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )

}