import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import moment from 'moment';
import { setMaintenanceWarning, closeMaintenance, sendMail } from '../Api/Api';

export default function IGModal({show,handleClose,userId}){

    const hide = () => {
        handleClose();
    }

    return (
        <>
            <Modal size="lg" show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Informar Tratativa Geral da Unidade
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6 className="subtitle_">Campo destinado para informes de insucesso ao tentar contato com a unidade</h6>
                    <form id="form" noValidate>
                        <div className='col-12'>
                            <div className="form-floating">
                            </div>
                            <div className="form-floating">
                                <textarea className="form-control" id="descRport" placeholder="Descrição" aria-label='Descrição'></textarea>
                                <label for="descRport">Descrição</label>
                            </div>
                        </div>
                        <br/>
                        <div className='col-12'>
                            <button type="submit" className='btn btn-primary float-end'>Salvar</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}