import React, { useEffect, useState }from 'react';
import { Chart } from "react-google-charts";
import { getCountTratativas, getQuantTratativasOp, getSupervisaoMaintenance } from '../Api/Api';

export default function Supervision(){
    const currentDate = new Date();

    const [ info, setInfo ] = useState({ 
        criticas: 0, 
        baixas: 0,
        tratadas: 0,
        encerradas: 0
    });

    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());

    const formattedDate = `${day}/${month}/${year}`;

    const optionsPie = {
        title: "Tratativas por operador",
        sliceVisibilityThreshold: 0
    };

    const [dataPie, setDataPie] = useState([["Operador", "Quantidade de tratativas"]]);
    const [dataBar, setDataBar] = useState([["Tipo", "Urgentes", "Altas", "Baixas"]]);

    const optionsCombo = {
        title: "Total de NCs tratadas | últimos 3 meses",
        vAxis: { title: "NCs" },
        hAxis: { title: "Mês" },
        seriesType: "bars",
        series: {
          0: { color: "#dc3545" },
          1: { color: "#ff9900" },
          2: { color: "#1a8754" },
        },
        legend: {
          position: "bottom",
        },
    };

    useEffect(() => {
        const fetchData = async () => {
            const configureDataPie = async () => {
                const res = await getQuantTratativasOp();
                if (res.data) {
                    const newDataPie = res.data.map(element => [
                        element.operator_name,
                        Number(element.treatment_count)
                    ]);
                    setDataPie([["Operador", "Quantidade de tratativas"], ...newDataPie]);
                }
            };
    
            const configureDataBar = async () => {
                const res = await getCountTratativas();
                if (res.data) {
                    const newDataBar = res.data.map(element => [
                        element.data_tratativa,
                        Number(element.tUrgente),
                        Number(element.tAlta),
                        Number(element.tBaixa)
                    ]);
                    setDataBar([["Tipo", "Urgentes", "Altas", "Baixas"], ...newDataBar]);
                }
            };
    
            const configureInfo = async () => {
                const res = await getSupervisaoMaintenance();
                if (res.data) {
                    setInfo(res.data[0]);
                }
            };
    
            await configureDataPie();
            await configureDataBar();
            await configureInfo();
        };
    
        fetchData();
    
        const interval = setInterval(fetchData, 60000);
        return () => clearInterval(interval);
    
    }, [setDataPie, setDataBar, setInfo]);

    return(
        <>
            {/* NÃO EXCLUIR AINDA */}
            {/* <div className="pagetitle">
                <div className="card search pt-0">
                    <div className="card-body">
                        <form onSubmit={handleSubmit(searchIndicators)}>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Mês</label>
                                    <select className="form-select">
                                        <option value="">Selecione</option>
                                        <option value="01">Janeiro</option>
                                        <option value="02">Fevereiro</option>
                                        <option value="03">Março</option>
                                        <option value="04">Abril</option>
                                        <option value="05">Maio</option>
                                        <option value="06">Junho</option>
                                        <option value="07">Julho</option>
                                        <option value="08">Agosto</option>
                                        <option value="09">Setembro</option>
                                        <option value="10">Outubro</option>
                                        <option value="11">Novembro</option>
                                        <option value="12">Dezembro</option>
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <label>Ano</label>
                                    <select className="form-select">
                                        <option value="">Selecione</option>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024" selected>2024</option>
                                    </select>
                                </div>
                                <div className="col-md-1 d-flex align-items-end">
                                    <button className="btn btn-secondary w-100" type="submit">Filtrar</button>
                                </div>
                            </div>  
                        </form>
                    </div>
                </div>
            </div> */}
            <section className="section">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body m-2">
                            <div className="row">
                                <h1 className="text-center p-3 font-weigh-bold">PAINEL DE SUPERVISÃO</h1>
                            </div>
                            <div className="row">
                                <h4 className="text-center font-weigh-bold">{formattedDate}</h4>
                            </div>
                            <div className="row d-flex justify-content-around">
                                <div className="col-2">
                                    <div className="card">
                                    <div className="card-body bg-NCs-criticals">
                                        <div className="card-title text-center">
                                            <i className="bi bi-list-columns-reverse"></i>
                                            <h2 className="mt-4"><b>{ info.criticas }</b></h2>
                                            <h5><b>NCs críticas</b></h5>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="card">
                                    <div className="card-body bg-NCs">
                                        <div className="card-title text-center">
                                            <i className="bi bi-list-columns-reverse"></i>
                                            <h2 className="mt-4"><b>{ info.baixas }</b></h2>
                                            <h5><b>NCs baixas</b></h5>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="card">
                                    <div className="card-body bg-NCs-day">
                                        <div className="card-title text-center">
                                            <i className="bi bi-calendar-event-fill"></i>
                                            <h2 className="mt-4"><b>{ info.tratadas }</b></h2>
                                            <h5><b>NCs tratadas/dia</b></h5>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="card">
                                    <div className="card-body bg-NCs-day">
                                        <div className="card-title text-center">
                                            <i className="bi bi-calendar2-check-fill"></i>
                                            <h2 className="mt-4"><b>{ info.encerradas }</b></h2>
                                            <h5><b>NCs encerradas/dia</b></h5>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className='col-6'>
                                    <Chart
                                        chartType="PieChart"
                                        data={dataPie}
                                        options={optionsPie}
                                        width={"100%"}
                                        height={"400px"}
                                    />
                                </div>
                                <div className='col-6 d-flex'>
                                    <Chart
                                        chartType="ComboChart"
                                        data={dataBar}
                                        options={optionsCombo}
                                        width={"100%"}
                                        height={"400px"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}