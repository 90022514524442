import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import moment from 'moment';
import Table from '../Table/Table';
import { loadClientDocument, saveMaintenance, setMaintenanceDoc, listClientAddresses } from '../Api/Api';

export default function DVModal({show, handleClose, client_ID, userName}){

    const $ = require('jquery');
    
    const [ docDaysBefore ] = useState(365);
    const [ clientIDparent, setClientIDparent ] = useState();
    const [ documents, setDocumentsData ] = useState([]);

    const filteredCompliances = documents.filter(row => row.document_status !== "RESOLVIDO");

    const columns = [
        {
            title: 'Documento',
            data: 'document_name',
            className: 'text-center align-middle',
            createdCell: function (cell, cellData, rowData) {        
                if (rowData.document_type_id == '20') {
                    $(cell).addClass('criticals');
                }
            }
        },
        {
            title: 'Emissão',
            data: 'document_emitted',
            className: 'text-center align-middle',
        },
        {
            title: 'Validade',
            data: 'document_expiration',
            className: 'text-center align-middle',
        },
        {
            title: 'Dias restantes',
            data: 'valid_days',
            className: 'text-center align-middle',
            render: function (data, type, row) {
                let badgeClass = '';
                let badgeContent = '';
            
                if (row.valid_days <= 3) {
                  badgeClass = 'badge bg-danger blink_me';
                } else if (row.valid_days > 3 && row.valid_days <= 15) {
                  badgeClass = 'badge bg-danger';
                } else if (row.valid_days > 15 && row.valid_days <= 45) {
                  badgeClass = 'badge bg-warning';
                } else if (row.valid_days > 45) {
                  badgeClass = 'badge bg-info';
                }
            
                badgeContent = Math.sign(row.valid_days) === -1 ? 'Vencido' : row.valid_days + ' dia(s)';
            
                return `<span class="${badgeClass}">${badgeContent}</span>`;
            },
        },
        {
            title: 'Status',
            data: 'document_status',
            className: 'text-center align-middle',
            render: function (data, type, row) {
                let statusColor = "badge bg-secondary";
                let statusName = "N/A";

                if (row.document_status == "RESOLVIDO"){
                    statusColor = "badge bg-success";
                    statusName = "Resolvido";
                    return null; //oculta os resolvidos
                } else if (row.document_status == "EM TRATATIVA"){
                    statusColor = " badge bg-warning text-dark";
                    statusName = "Em tratativa";
                }

                return `<span class="${statusColor}">${statusName}</span>`;
            }
        },
        {
            title: 'Ações',
            data: null,
            className: 'text-center align-middle',
            visible: false
        }
    ];

    const actionButtons = (row) => {
        const generateNCButton = (
            <button
              className="btn btn-sm btn-outline-danger btnList"
              onClick={() => generateNC(row.document_id, row.document_name, row.document_type_id)}
            >
              Gerar NC
            </button>
          );
        
          return (
            <>
                {row.document_status === null && row.valid_days <= 15 ? generateNCButton : <span>-</span>}
            </>
        );
    }

    const hide = () => {
        handleClose();
    }

    const loadClientDoc = async (daysBefore) => {
        const res = await loadClientDocument(client_ID, daysBefore);
        if(res) {
            setDocumentsData(res.data);
            getFirtsCliIdPassByParentId(client_ID);
        }
    }

    // Só vai funcionar se o primeiro endereço cadastrado for aquele que esta sendo alterado os sistemas (unidades antigas podem dar problema)
    const getFirtsCliIdPassByParentId = async (client_ID) => {
        const res = await listClientAddresses(client_ID);
        if(res.length > 0) {
            setClientIDparent(res.data[0].client_id);
        }
    }

    const generateNC = async (document_id, document_name, document_type_id) => {
        let systemMaintenanceText = "";
        let maintenanceTypeId = "";

        if (document_type_id == "20") {
            systemMaintenanceText = "O documento do tipo AVCB '" + document_name + "' está pendente de tratativa em relação à sua data de vencimento";
            maintenanceTypeId = "280";
        } else {
            systemMaintenanceText = "O documento '" + document_name + "' está pendente de tratativa em relação à sua data de vencimento";
            maintenanceTypeId = "194";
        }

        Swal.fire({
            backdrop: true,
            didOpen: async () => {
                Swal.showLoading();
                let postData = {
                    system_maintenance_text: systemMaintenanceText,
                    system_maintenance_action: "Adequação do Documento",
                    system_maintenance_created: moment().format("YYYY-MM-DD HH:mm"),
                    system_type_id: "15",
                    maintenance_type_id: maintenanceTypeId,
                    user_id: sessionStorage.getItem('gestao_userId'),
                    client_id: clientIDparent
                }

                await saveMaintenance(postData)
                .then((response) => {

                    let res = response.data;

                    if(res.id) {
                        let postDataNC_doc = {
                            document_id: document_id,
                            system_maintenance_id: res.id
                        }
                        
                        setMaintenanceDoc(postDataNC_doc)
                        .then((response) => {

                            let resp = response.data;

                            if(resp.data == "success") {
                                Swal.fire({
                                    title: 'NC criada com sucesso!',
                                    icon: 'success',
                                    allowOutsideClick: false,
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'Ok'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        setDocumentsData([]);
                                        loadClientDoc(docDaysBefore);
                                        hide();
                                    }
                                })
                            }
                        }).catch((error) => {
                            Swal.fire("OPS!", "O servidor encontrou um erro ao processar sua requisição", "error");
                            hide();
                        });
                    }
                }).catch((error) => {
                    //O endereço cadastrado que possui os sistemas não é o Index [0]
                    Swal.fire("OPS!", "Você não possui o Sistema de Proteção específico relacionado com a Unidade em questão", "error");
                    hide();
                });
            },
        })
    }

    useEffect(()=>{
        loadClientDoc(docDaysBefore);
    }, [client_ID]);

    return (
        <>
            <Modal size="lg" show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {userName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12">
                        <div className="card recent-sales">
                            <div className="card-header">
                                <b>Documentos a vencer</b> | próximos {docDaysBefore} dias
                            </div>
                            <div className="card-body">
                                <Table data={filteredCompliances} columns={columns} btnColumn={5} actBtns={actionButtons}/>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}