const utils = {
    isNullOrEmpty: (value) => {
        return value === null || value === '';
    },
    blobToString: async (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsBinaryString(blob);
        });
    },
};
  
export default utils;