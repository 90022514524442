import React from "react";
import axios from "axios";
import { baseApi, auth } from './Api';

const checkLogin = async (data) => {
    var res;
    const headers = {
        'Authorization': `Bearer ${auth}`,
        'Content-Type': 'application/json'
    }
    await axios.post(`${baseApi}/login/checklogin`,JSON.stringify(data), {headers}
    ).then((response) => {
        res = response.data.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

export {
    checkLogin
}