import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'
import './datatables.css';
import JSZip from 'jszip';
window.JSZip = JSZip;

const $ = require('jquery');
$.DataTable = require('datatables.net');
$.DataTable.buttons = require( 'datatables.net-buttons/js/buttons.html5.js' );

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.order || [], // Cria a config. da ordenação
      keepState: this.props.keepState || false
    };
  }

  componentDidMount() {
    this.$el = $(this.el);
    this.$el.DataTable({
      //dom: `<"data-table-wrapper"${this.props.dom ? this.props.dom : 'lfrtip'}>`,
      dom: "<'row'<'col-sm-6'B><'col-sm-6'f>>rtip",
      buttons: [
        {
          extend: 'excel',
          text: 'Excel completo',
          className: 'btn btn-outline-secondary',
        },
        {
          extend: 'excel',
          text: '&nbsp;&nbsp;Excel pagina&nbsp;&nbsp;&nbsp;',
          className: 'btn btn-outline-secondary',
          exportOptions: {
              modifier: {
                  page: 'current'
              }
          }
        }
      ],
      data: this.props.data,
      columns: this.props.columns,
      ordering: true,
      paging: true,
      processing: true,
      serverSide: false,
      order: this.state.order,
      // language: {
      //   url: `/assets/vendor/simple-datatables/pt_BR.json`
      // }, 
      language: {
        "emptyTable": "Nenhum registro encontrado",
        "info": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        "infoEmpty": "Mostrando 0 até 0 de 0 registros",
        "infoFiltered": "(Filtrados de _MAX_ registros)",
        "infoPostFix": "",
        "infoThousands": ".",
        "lengthMenu": "_MENU_ resultados por página",
        "loadingRecords": "Carregando...",
        "processing": "Processando...",
        "zeroRecords": "Nenhum registro encontrado",
        "search": "Pesquisar",
        "paginate": {
            "next": "Próximo",
            "previous": "Anterior",
            "first": "Primeiro",
            "last": "Último"
        },
        "aria": {
            "sortAscending": ": Ordenar colunas de forma ascendente",
            "sortDescending": ": Ordenar colunas de forma descendente"
        }
      },         
      columnDefs: [
        { 
          visible: true, 
          orderable: false, 
          searchable: false, 
          className: "text-center", 
          targets: [this.props.btnColumn],
          createdCell: (td, cellData, rowData) => ReactDOM.render (
            this.props.actBtns(rowData),
            td
          ),
        },
        {           
          targets: [this.props.edtColumn],
          createdCell: (td, cellData, rowData) => ReactDOM.render (
            <>
              <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => this.props.goto(rowData.id) }>
                {cellData}
              </span>
            </>,
            td
          ),
        },
      ]
    });
  }

  componentWillUnmount(){
    //$('.data-table-wrapper').find('table').DataTable().destroy(true);
    // $(`#${this.props.id}`).DataTable().destroy(true);
    this.$el.DataTable().destroy(true);
  }

  shouldComponentUpdate(nextProps) {
    this.reloadTableData(nextProps.data);
    
    return false;
  }

  reloadTableData = (data) => {
    // const table = $('.data-table-wrapper').find('table').DataTable();
    // const table = $(`#${this.props.id}`).DataTable();
    const table = this.$el.DataTable();
    table.clear();
    table.rows.add(data);

    const pageInfo = table.page.info();
    const currentPage = pageInfo.page;
    const rowsPerPage = pageInfo.length;
    const recordsTotal = pageInfo.recordsTotal;

    if (!this.state.keepState || currentPage > (recordsTotal/rowsPerPage)) {
      table.draw();
      return;
    } 
    
    if (this.state.keepState) {
      table.draw("full-hold");
      return;
    }
  }

  render() {
    return (
      <>
        <table className="table table-borderless display" id={this.props.id} width="100%" cellSpacing="0" ref={(el) => (this.el = el)} />
      </>
    );
  }
}

Table.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    actBtns: PropTypes.func,
    order: PropTypes.array,
    keepState: PropTypes.bool
};

Table.defaultProps = {
  order: [],
  keepState: false
};

export default Table;