import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';

import MultipleFilesComponent from './MultipleFilesComponent';
import HistoryModal from '../Main/HistoryModal';
import { closeMaintenance } from '../Api/Api';

import './css/issuesClosure.css';

export default function IssuesClosureModal({ systemMaintenance, onClose, isOpen = false}) {
    const [evidences, setEvidences] = useState([]);
    const [descTratNC, setDescTratNC] = useState('');
    const [encarregado, setEncarregado] = useState('');
    const [hShow, setHShow] = useState(false);
    const {handleSubmit} = useForm();

    if (!isOpen) return (<></>);

    const save = (data) => { 
        Swal.fire({
            backdrop: true,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();

                const formData = new FormData();
                formData.append('user_id', sessionStorage.getItem("gestao_userId"));
                formData.append('system_maintenance_id', systemMaintenance?.system_maintenance_id);
                formData.append('descTratNC', descTratNC);
                formData.append('encarregado', encarregado);

                formData.append('totalFiles', evidences.length);

                for (let i = 0; i < evidences.length; i++) {
                    let varName = "file"+i;
                    formData.append(varName, evidences[i]);
                }

                const res = await closeMaintenance(formData);
                
                if (res.data.data === 'success') {
                    Swal.fire({
                        title: 'NC encerrada com sucesso!',
                        icon: 'success',
                        allowOutsideClick: false,
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            onClose();
                            Swal.close();
                        }
                    });
                } else {
                    Swal.fire('OPS!', res.messages.error, 'error');
                }
            }
        });

        onClose();
    }

    const handleFilesChange = (response) => {
        let files = [];

        if (response.success === true) {
            response.files.forEach((file) => {
                files.push(file);
            });
            setEvidences(files);
        } else {
            Swal.fire("OPS!", response.errorMsg, "error");
        }
    }

    const handleDescTratNCChange = (event) => {
        setDescTratNC(event.target.value);
    };

    const handleEncarregadoChange = (event) => {
        setEncarregado(event.target.value);
    };

    const handleHShow = () => {
        setHShow(true);
    }

    const handleHClose = () => {      
        setHShow(false);
    }

    return (
        <>
            <Modal className='modal-padding' show={isOpen} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Encerramento da NC #{systemMaintenance?.system_maintenance_id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(save)}>
                        <div className="col-md-12 col-xs-12 mb-3">
                            <div className="form-group">
                                <textarea 
                                    id="descTratNC" 
                                    className="form-control mb-3" 
                                    placeholder="Descrição" 
                                    value={descTratNC}
                                    onChange={handleDescTratNCChange} 
                                    required={true}>
                                </textarea>
                            </div>
                            <div className="form-group">
                                <input 
                                    id="encarregado" 
                                    className="form-control mb-3" 
                                    placeholder="Nome do encarregado" 
                                    value={encarregado}  
                                    onChange={handleEncarregadoChange} required={true} />
                            </div>
                            <MultipleFilesComponent 
                                maxFiles={3-evidences.length}
                                showDefaultGrid={true}
                                onChange={handleFilesChange} 
                                required={true} />
                        </div>
                        <div className='mt-3 d-flex justify-content-between'>
                            <button 
                                type="button"
                                className="btn btn-md btn-secondary"
                                title="Infos NC"
                                onClick={() => handleHShow()}
                            >
                                <i className="bi bi-eye-fill"></i>
                            </button>
                            <button className='btn btn-md btn-danger' type="submit">Encerrar</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <HistoryModal hShow={hShow} handleHClose={handleHClose} systemMaintenance={systemMaintenance}/>
        </>
    )
};