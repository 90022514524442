import React, { useState, useEffect, useCallback }from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import Table from '../Table/Table';
import NCModal from './NCModal';
import './style.css';
import { getGestaoList, getWarningList, getChild, listClientActiveSystemsTypes } from '../Api/Api';

import IssuesClosureModal from '../Custom/IssuesClosureModal';

export default function Main(){
    const $ = require('jquery');
    const intervalMinutes = 1;

    const { clientId } = useParams();
    const { register, handleSubmit } = useForm();
    const [ list, setList ] = useState([]);
    const [ show, setShow ] = useState(false);
    const [ systemMaintenance, setSystemMaintenance ] = useState([]);
    const [ warningList, setWarningList ] = useState([]);
    const [ systemType, setSystemType ] = useState([]);
    const [showClosureModal, setShowClosureModal] = useState(false);
    const [filters, setFilters] = useState({
        clientId: clientId,
        systemId: null,
        ini: null,
        fim: null,
        prior: null
    });    

    const columns = [
        {
            title: 'NC',
            data: 'system_maintenance_id',
            className: 'text-center align-middle',
        },
        {
            title: 'Unidade',
            data: 'info_name',
            className: 'text-center align-middle',
        },
        {
            title: 'Sistema',
            data: 'system_type_name',
            className: 'text-center align-middle',
        },
        {
            title: 'Problema',
            data: 'system_maintenance_text',
            className: 'text-center align-middle',
        },
        {
            title: 'Prioridade',
            data: 'maintenance_type_priority',
            className: 'text-center align-middle',
            createdCell: function (cell, cellData) {
                if (cellData) {
                    if (cellData === 'Alta') {
                        $(cell).addClass('criticals');
                    } else if (cellData === 'Urgente') {
                        $(cell).addClass('urgente');
                    }
                }
            }
        },
        {
            title: 'Data de criação',
            data: 'system_maintenance_created',
            className: 'text-center align-middle',
            render: function (data) {
                if (data) {
                    return moment(data).format("L");
                }
            }
        },
        {
            title: 'Prazo sugerido',
            data: 'system_maintenance_expiration',
            className: 'text-center align-middle',
            render: function (data) {
                if (data) {
                    return moment(data).format("L");
                }
            }
        },
        {
            title: 'Prazo documental',
            data: 'document_expiration',
            className: 'text-center align-middle',
            render: function (data) {
                if (data) {
                    return data;
                }else{
                    return "-";
                }
            }
        },
        {
          title: '',
          data: null,
          className: 'text-center align-middle',
        }
    ];

    const actionButtons = (row) => {
        return row && (
          <>
            <button className="btn btn-sm btn-primary m-2" onClick={()=>handleShow(row)}>Tratativas</button>
            <button type="btn" className='btn btn-sm btn-danger pl-1 pr-1' onClick={()=>handleEnd(row)} >Encerrar</button>
          </>
        )
    }

    const listSystemType = useCallback(async () => {
        const chl = await getChild(clientId);
        if(chl){
            const res = await listClientActiveSystemsTypes(chl.data[0].client_id);
            if(res.data){
                setSystemType(res.data);
            }
        }
    }, [clientId]);

    const handleShow = async (row) => {
        sessionStorage.setItem("gestao_mainNcRow", JSON.stringify(row));

        await setSystemMaintenance([]);
        setShow(true);
        await setSystemMaintenance(row);
        const rst = await getWarningList(row.system_maintenance_id);
        if(rst){
            setWarningList(rst.data);
        }
    }

    const loadGrid = useCallback(() => {
        const sessionSearch = sessionStorage.getItem('gestao_searchJson');
        
        let searchFilters = filters; 
        if (sessionSearch) {
            searchFilters = JSON.parse(sessionSearch);
        }
    
        populateList(searchFilters);
    }, [filters]);
    
    const handleClose = () => {
        sessionStorage.removeItem("gestao_mainNcRow");

        loadGrid();

        setShow(false);
    }

    const handleClosureModal = async () => {
        setShowClosureModal(true);
    }

    const handleCloseClosureModal = () => {
        loadGrid();
        setShowClosureModal(false);
    }

    const handleEnd = async (row) => {
        await setSystemMaintenance(row);
        handleClosureModal();
    };

    const populateList = async (data) => {
        const res = await getGestaoList(data);
        if (res) {
            setList(res.data);
        } else {
            setList(undefined);
        }
    }

    const setSearch = async (data) => {
        if(data.systemId === ''){
            data.systemId = null;
        }
        if(data.ini === ''){
            data.ini = null;
        }
        if(data.fim === ''){
            data.fim = null;
        }

        setFilters({ ...filters, ...data });
        sessionStorage.setItem('gestao_searchJson', JSON.stringify(data));
        populateList(data);
    }

    useEffect(() => {
        const getList = async () => {
            const sessionSearch = sessionStorage.getItem('gestao_searchJson');

            if (sessionSearch) {
                const parsedSearch = JSON.parse(sessionSearch);

                if (parsedSearch.clientId !== clientId) {
                    sessionStorage.removeItem('gestao_searchJson');
                }
            }

            populateList(filters);
        }

        getList();
        listSystemType();

        const interval = setInterval(() => {
            if (!show) {
                const row = JSON.parse(sessionStorage.getItem("gestao_mainNcRow"));
                if (row) {
                    handleShow(row);
                }

                loadGrid();
            }
        }, 1000 * 60 * intervalMinutes);

        return () => { 
            sessionStorage.removeItem('gestao_mainNcRow');
            clearInterval(interval); 
        }
    }, [listSystemType, filters, loadGrid, clientId, show]);
    
    return(
        <>
        <div className="pagetitle">
            <div className="card search">
                <div className="card-body">
                    <form onSubmit={handleSubmit(setSearch)}>
                        <div className="row">
                            <input type="number" hidden value={clientId} {... register('clientId',{required:true})}/>
                            <div className="col-sm-12 col-md-4">
                                <label>Sistema</label>
                                <select className="form-select" {... register('systemId',{required:false})}>
                                    <option value="">Todos</option>
                                    {
                                        systemType.length ?  
                                        systemType.map((item,index) => {
                                            return(
                                                <option key={index} value={item.system_type_id}>{item.system_type_name}</option>
                                            )
                                        })
                                        :
                                        ''
                                    }
                                </select>
                            </div>
                            <div className="col-sm-12 col-md-2">
                                <label>Data Inicio</label>
                                <input type="date" className="form-control"  {... register('ini',{required:false})}/>
                            </div>
                            <div className="col-sm-12 col-md-2">
                                <label>Data Fim</label>
                                <input type="date" className="form-control"  {... register('fim',{required:false})}/>
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <label>Prioridade</label>
                                <select className="form-select" {... register('prior',{required:false})}>
                                    <option value="0">Todas</option>
                                    <option value="1">Baixa</option>
                                    <option value="2">Crítica</option>
                                </select>
                            </div>
                            <div className="col-md-1 d-flex align-items-end">
                                <button className="btn btn-secondary w-100" type="submit" >Filtrar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <section className="section">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        Lista de Não Conformidades
                    </div>
                    <div className="card-body">
                        <Table data={list} columns={columns} btnColumn={8} actBtns={actionButtons} keepState={true}/>
                    </div>
                </div>
            </div>
        </section>
        <IssuesClosureModal isOpen={showClosureModal} systemMaintenance={systemMaintenance} onClose={handleCloseClosureModal} />
        <NCModal show={show} handleClose={handleClose} userId={sessionStorage.getItem('gestao_userId')} systemMaintenance={systemMaintenance} warningList={warningList} setShow={setShow} />
        </>
    )
}