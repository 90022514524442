import React from 'react';
import { useForm } from "react-hook-form";
import { checkLogin } from "../Api/Api";
import './LoginStyle.css';
import Swal from 'sweetalert2';

const home = process.env.REACT_APP_HOMEPAGE;

export default function Choice(){

    const { register, handleSubmit } = useForm();

    const toMainRoute = () => {
        window.location.href = home;
    }

    const login = async (data) => {
        try {
            const res = await checkLogin(data);
            const erro = JSON.stringify(res.message);
            
            if(erro == undefined){
                sessionStorage.setItem('gestao_userId', res.user_id);

                toMainRoute();
            } else {
                const errorMessage = res?.response.data.messages.error || "Erro desconhecido";
                Swal.fire({
                    position: 'top',
                    icon: 'error',
                    text: errorMessage,
                    showConfirmButton: true
                });
            }
        } catch (error) {
            Swal.fire({
                position: 'top',
                icon: 'error',
                text: `Ocorreu um erro ao processar sua requisição: ${error.message}`,
                showConfirmButton: true
            });
        }
    };

    return(
        <div className="wrapper fadeInDown">
            <div id="formContent">
                <div className="fadeIn first">
                    <div className="logoSvgLogin" />
                    <span className="titleLogin">Gestão de Contas</span>
                </div>
                <form onSubmit={handleSubmit(login)}>
                    <input type="text" className="fadeIn second" placeholder="email" {...register('username',{required:true})} />
                    <input type="password" className="fadeIn third" placeholder="Senha" {...register('password',{required:true})} />
                    <input type="submit" className="fadeIn fourth" value="ENTRAR" />
                </form>
            </div>
        </div>
    );
}