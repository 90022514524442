import React from 'react';
import Modal from 'react-bootstrap/Modal';

export default function ZoomModal({ showModal_, handleCloseModal_, src }) {
    if (!src) return null;

    const { url, type } = src;

    return (
        <Modal size="lg" show={showModal_} onHide={handleCloseModal_} style={{ backgroundColor: "#212121a6" }}>
            <Modal.Header closeButton />
            <Modal.Body>
                {type !== "pdf" && type !== "video" ? (
                    <div className="container containerCenter">
                        <img className="w-100" src={url} alt='' />
                    </div>
                ) : type === "video" ? (
                    <div>
                        <video className="form-control w-100" 
                            src={url} 
                            controls
                        />
                    </div>
                ) : (
                    <div style={{ height: "1000px" }}>
                        <iframe
                            title="pdfRender"
                            style={{ height: "100%", width: "100%" }}
                            src={URL.createObjectURL(new Blob([url], { type: 'application/pdf' }))}
                        ></iframe>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    )
}