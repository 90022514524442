import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ZoomModal from '../Main/ZoomModal';
import utils from '../../Helpers/FunctionsHelpers';
import defaultPDF from '../../images/pdf.jpeg';
import defaultVideo from '../../images/mp4.png';
import {loadMaintenance, downloadMaintenance} from '../Api/Api';

export default function HistoryModal({ hShow, handleHClose, systemMaintenance }) {

    const baseUrlImg = process.env.REACT_APP_URL_IMGS;
    const [issue, setIssue] = useState(null);
    const [files, setFiles] = useState([]);
    const [fileModal, setFileModal] = useState(null);
    const [showModal_, setModal] = useState(false);
    const [loading, setLoading] = useState(true);

    var issueID = systemMaintenance.system_maintenance_id;

    const loadIssueData = async (issueID) => {
        const res = await loadMaintenance(issueID);
        if (res) {
            setIssue(res);

            if (res.main_file_ids) {
                const fileIds = res.main_file_ids.split(',');
                const downloadPromises = fileIds.map(id => downloadFile(id));
                await Promise.all(downloadPromises);
            }

            setLoading(false);
        }
    };

    const downloadFile = async (id) => {
        if (files.length > 0) return;
    
        const res = await downloadMaintenance(id);
    
        if (res) {
          const { blob, mimeType } = res;
          const blobString = await utils.blobToString(blob);
    
            if (blobString.startsWith('%PDF-')) {
                setFiles((files) => [...files, { url: defaultPDF, content: blob, type: "pdf" }]);
            } else if (mimeType.includes('video')) {
                let url_ = baseUrlImg + res.url?.match(/uploads\/(.*)$/)?.[1];
                setFiles((files) => [...files, { url: defaultVideo, url_: url_, type: "video" }]);
            } else {
                const url = window.URL.createObjectURL(blob);
                setFiles((files) => [...files, { url: url, type: "image" }]);
            }
        }
    };

    const expandImg = async (src) => {
        setFileModal(src);
        setModal(true);
    };

    const handleCloseModal_ = () => {
        setModal(false);
    };

    const hideBrokenImg = (event) => {
        event.target.style.display = 'none';
    };

    useEffect(() => {
        setFiles([]);
        setLoading(true);
        loadIssueData(issueID);
    }, [systemMaintenance, issueID]);

    return (
        <>
            <Modal size="xl" show={hShow} onHide={handleHClose} style={{ backgroundColor: "#212121a6" }}>
                <Modal.Header style={{ backgroundColor: "#bbbbbb" }} closeButton>
                    <Modal.Title>NC #{systemMaintenance?.system_maintenance_id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <span className="input-group-text groupEqual">Unidade</span>
                                    <span className="form-control">{systemMaintenance?.info_name}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <span className="input-group-text groupEqual">Localidade</span>
                                    <span className="form-control">
                                        {issue?.address_street + ", " + issue?.address_number}
                                        <br />
                                        {issue?.city_name + " - " + issue?.state_name + " - " + issue?.state_acronym}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <span className="input-group-text groupEqual">Sistema de Proteção</span>
                                    <span className="form-control">{systemMaintenance?.system_type_name}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <span className="input-group-text groupEqual">Identificação</span>
                                    <span className="form-control">{issue?.maintenance_type_name}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <span className="input-group-text groupEqual">Descricao</span>
                                    <span className="form-control">{systemMaintenance?.system_maintenance_text}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <span className="input-group-text groupEqual">Solução</span>
                                    <span className="form-control">{systemMaintenance?.system_maintenance_action}</span>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                        {
                            loading ?
                            <div className='col-12 text-center mt-3'>
                                <div className="spinner-border spinner-border-lg text-secondary" role="status">
                                <span className="visually-hidden"></span>
                                </div>
                                <p className="mt-2">Carregando evidências...</p>
                            </div>
                            :
                            <>
                            {files && files.map((file, index) => (
                                <div key={index} className='col-3 mt-3 pointer' style={{ maxHeight: 220, maxWidth: 391.11 }}>
                                    <img className="form-control" style={{ height: "100%", width: "100%", objectFit: 'contain' }} 
                                    src={file.url}
                                    onClick={() => {
                                        if (file.type === "pdf") {
                                        expandImg({ url: file.content, type: "pdf" });
                                        } else if (file.type === "video") {
                                        expandImg({ url: file.url_, type: "video" });
                                        } else {
                                        expandImg(file);
                                        }
                                    }}
                                    onError={(event) => hideBrokenImg(event)}
                                    />
                                </div>
                            ))}
                            </>
                        }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ZoomModal showModal_={showModal_} handleCloseModal_={handleCloseModal_} src={fileModal} />
        </>
    );
}