import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default function Sidebar() {
  const [ sidebar, setSidebar ] = useState(null);

  return (
    <aside id="sidebar" className="sidebar" ref={setSidebar}>
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <a className="nav-link collapsed" data-bs-target="#nav-opera" data-bs-toggle="collapse" href="#">
            <i className="bi bi-card-list"></i><span>Operacional</span><i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="nav-opera" className="nav-content collapse" data-bs-parent="#sidebar-nav">
            <li>
              <NavLink to="/gestao">
                <i className="bi bi-calendar-event"></i><span>Unidades (diário)</span>
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <a className="nav-link collapsed" data-bs-target="#nav-adm" data-bs-toggle="collapse" href="#">
            <i className="bi bi-person-badge"></i><span>Administrativo</span><i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="nav-adm" className="nav-content collapse" data-bs-parent="#sidebar-nav">
            <li>
              <NavLink to="gestao/list/urgents">
                <i className="bi bi-exclamation-triangle"></i><span>Urgentes/Críticos (diário)</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="gestao/list/supervision">
                <i className="bi bi-file-earmark-bar-graph"></i><span>Painel de supervisão</span>
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </aside>
  );
}