import React, { useState, useEffect, useCallback }from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Table from '../Table/Table';
import InfoModal from './InfoModal';
import IGModal from './IGModal';
import DVModal from './DVModal';
import EVModal from './EVModal';
import RelatModal from './RelatModal';

import { listOrganizationClients, listOrganizations } from '../Api/Api';

export default function Daily(){

    const [ clients, setClients ] = useState([]);
    const [ clientName, setCllientName ] = useState();
    const [ show, setShow ] = useState(false);
    const [ igShow, setIGShow ] = useState(false);
    const [ dvShow, setDVShow ] = useState(false);
    const [ evShow, setEVShow ] = useState(false);
    const [ relatShow, setRelatShow ] = useState(false);
    const [ clientID, setClientID ] = useState(false);

    const [ orgList, setOrgList ] = useState([]);
    const [ orgSelected, setOrgSelected ] = useState("");

    const { register } = useForm();
    const navigate = useNavigate();
    const intervalMinutes = 1;

    const columns = [
        {
            title: 'Client Id',
            data: 'client_id',
            className: 'text-center align-middle',
            visible: false,
        },
        {
            title: 'Nome',
            data: 'info_name',
            className: 'text-center align-middle',
        },
        {
            title: 'Urgentes',
            data: 'urgentes',
            className: 'text-center align-middle',
        },
        {
            title: 'Altas',
            data: 'altas',
            className: 'text-center align-middle',
        },
        {
            title: 'Baixas',
            data: 'baixas',
            className: 'text-center align-middle',
        },
        {
            title: 'Total',
            data: 'total',
            className: 'text-center align-middle',
            render: (data, x, row) => {
                if (data) {
                    return `<b>${data}</b>`
                }
            }
        },
        {
            title: 'Ações',
            data: null,
            className: 'text-center align-middle',
        }
    ];

    const actionButtons = (row) => {
        return row && (
          <>
            <button className="btn btn-sm btn-primary m-1" onClick={() => toClient(row.client_id)}>Acesse aqui</button>
            <button type="button" className="btn btn-sm btn-secondary m-1" onClick={()=>handleShow(row.client_id)}>Informações</button>
            <button type="button" className="btn btn-sm btn-danger m-1"  title="Documentos a vencer" onClick={()=>handleDVShow(row.client_id, row.info_name)}><i className="bi bi-calendar-week"></i></button>
            <button type="button" className="btn btn-sm btn-danger btn-orange m-1"  title="Eventos a vencer" onClick={()=>handleEVShow(row.client_id, row.info_name)}><i className="bi bi-calendar2-event"></i></button>
            {
                /* <button type="button" className="btn btn-sm btn-secondary m-1" title="Relatório" onClick={()=>handleRelatShow(row.client_id)}><i className="bi bi-file-earmark-text text-white"></i></button>
                <button type="button" className="btn btn-sm btn-danger m-1"  title="Informar interação geral" onClick={()=>handleIGShow(row.client_id)}><i className="bi bi-exclamation-circle  text-white"></i></button> */
            }
          </>
        )
    }

    const filter = useCallback(async (data) => {
        if(data.org === ''){
            data.org = null;
        }

        sessionStorage.setItem('gestao_unitSearch', data.org);
        setOrgSelected(data.org);
        getOrganizationClients(data.org);
    }, []);

    const handleShow = (Client_ID) => {
        setClientID(Client_ID);
        setShow(true);
    }

    /*const handleIGShow = (Client_ID) => {
        setClientID(Client_ID);
        setIGShow(true);
    }

    const handleRelatShow = (Client_ID) => {
        setClientID(Client_ID);
        setRelatShow(true);
    }*/

    const handleDVShow = (Client_ID, Client_Name) => {
        setClientID(Client_ID);
        setCllientName(Client_Name);
        setDVShow(true);
    }

    const handleEVShow = (Client_ID, Client_Name) => {
        setClientID(Client_ID);
        setCllientName(Client_Name);
        setEVShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleIGClose = () => {
        setIGShow(false);
    }

    const handleRelatClose = () => {
        setRelatShow(false);
    }

    const handleDVClose = () => {
        setDVShow(false);
    }

    const handleEVClose = () => {
        setEVShow(false);
    }

    const getOrganizationClients = async (orgId = null) => {
        const res = await listOrganizationClients(orgId);

        if (res.data) {
            setClients(res.data);
        } else {
            setClients([]);
        }
    }

    const getAllOrganizations = async () => {
        const res = await listOrganizations();
        if(res){
            setOrgList(res.data);
        }
    }

    const toClient = (id) => {
        navigate(`/gestao/${id}`);
    }

    useEffect(()=>{
        setClientID(0);
        getOrganizationClients();
        getAllOrganizations();

        const interval = setInterval(() => {
            getOrganizationClients(sessionStorage.getItem('gestao_unitSearch'));
        }, 1000 * 60 * intervalMinutes);

        return () => { 
            sessionStorage.removeItem('gestao_unitSearch');
            clearInterval(interval)
        };
    }, [filter]);

    return(
        <>
            <div className="pagetitle">
                <div className="card search pt-0">
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-5 col-xs-12">
                                    <label>Organização</label>
                                    <select
                                        className="form-select"
                                        id="org"
                                        aria-label="Organização"
                                        {...register('org')}
                                        value={orgSelected}
                                        onChange={(e) => {
                                            filter({ org: e.target.value });
                                        }}>
                                    <option value="">Todos</option>
                                    {
                                        orgList.length > 0 &&
                                        orgList.map((item, index) => {
                                        return (
                                            <option key={index} value={item.client_id}>{item.organization_name}</option>
                                        )
                                        })
                                    }
                                    </select>
                                </div>
                            </div>  
                        </form>
                    </div>
                </div>
            </div>
            <section className="section">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            Relação de unidades (diário)
                        </div>
                        <div className="card-body">
                            <Table data={clients} columns={columns} btnColumn={6} actBtns={actionButtons} />
                        </div>
                    </div>
                </div>
            </section>
            <InfoModal show={show} handleClose={handleClose} client_ID={clientID}/>
            <IGModal show={igShow} handleClose={handleIGClose} userId={sessionStorage.getItem('gestao_userId')} />
            <RelatModal show={relatShow} handleClose={handleRelatClose} userId={sessionStorage.getItem('gestao_userId')} />
            <DVModal show={dvShow} handleClose={handleDVClose} userName={clientName} client_ID={clientID}/>
            <EVModal show={evShow} handleClose={handleEVClose} userName={clientName} client_ID={clientID}/>
        </>
    )
}