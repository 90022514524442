import React, { useState } from 'react';

/**
 * Componente de input para múltiplos arquivos.
 * @param {Object} props - As propriedades do componente.
 * @param {function} props.onChange - Função chamada quando os arquivos são alterados.
 * @param {number} [props.maxFiles=3] - O número máximo de arquivos permitidos.
 * @param {number} [props.maxSize=2] - O tamanho máximo permitido para cada arquivo em MB.
 * @param {string[]} [props.allowedExtensions=[]] - As extensões de arquivo permitidas.
 * @param {boolean} [required=false] - Padrão: false. Define se o campo será obrigatório dentro do formulário.
 * @param {boolean} [showDefaultGrid=false] - Padrão: false. Apresenta um grid com nome e tamanho do arquivo.
 */
const MultipleFilesComponent = ({ 
    onChange, 
    maxFiles = 3, 
    maxSize = 128, // 128MB de acordo com o configurável no PHP atualmente
    allowedExtensions = ["JPG","JPEG","PNG","PDF","MP4"],
    required = false,
    showDefaultGrid = false
}) => {
    const [files, setFiles] = useState([]);

    const handleChange = (e) => {
        const filesRetorn = [];

        const allowExt = allowedExtensions.map(ext => ext.toLowerCase());
        const mSize = 1024 * 1024 * maxSize;

        let selectedFiles = Array.from(e.target.files);

        // Filtrar arquivos além do limite
        if (selectedFiles.length > maxFiles) {
            let errorMsg = `Você só pode selecionar até ${maxFiles} arquivos`;
            onChange({ errorMsg, success: false, files });
            e.target.value = '';
            return;
        }

        // Filtrar arquivos com extensões não permitidas
        if (selectedFiles.some(file => !allowExt.includes(file.name.split('.').pop().toLowerCase()))) {
            let errorMsg = "Um ou mais arquivos possuem uma extensão inválida";
            onChange({ errorMsg, success: false, files });
            e.target.value = '';
            return;
        }

        // Filtrar arquivos com tamanho maior que maxSize
        if (selectedFiles.some(file => file.size > mSize)) {
            let errorMsg = "Um ou mais arquivos selecionados excedem o tamanho máximo permitido";
            onChange({ errorMsg, success: false, files });
            e.target.value = '';
            return;
        }

        const newFiles = [...filesRetorn, ...selectedFiles];
        setFiles(newFiles);
        onChange({ errorMessage: '', success: true, files: newFiles });
    };

    function byteConverter( bytes, decimals, only) {
        const K_UNIT = 1024;
        const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    
        if(bytes== 0) return "0 Byte";
      
        if(only==="MB") return (bytes / (K_UNIT*K_UNIT)).toFixed(decimals) + " MB" ;
      
        let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
        let resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + " " + SIZES[i];
      
        return resp;
    };

    return (
        <>
            <input type="file" className="form-control" onChange={handleChange} multiple required={required} />
            <p className="mt-2">
                <small>
                    - Tamanho máximo de {maxSize}MB/arquivo
                    <br />
                    - São aceitas as extensões {allowedExtensions.join(", ")}
                    <br />
                    - Limite máximo de até 3 arquivos por NC/C
                    <br />
                    {/* <i>As linhas em vermelho serão ignoradas, pois, estão em desacordo com uma ou mais regras</i> */}
                </small>
            </p>
            {showDefaultGrid && <>
                <div className="row">
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col" className='text-center'>Título</th>
                            <th scope="col" className='text-center'>Tamanho</th>
                        </tr>
                        </thead>
                        <tbody>
                        {files.length > 0 ?
                                files.map((item, index) => {
                                    return (
                                    <tr key={index}>
                                        <td className='text-center'>{item.name}</td>
                                        <td className='text-center'>{byteConverter(item.size, 2, "MB")}</td>
                                    </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={2} className="text-center">Nenhuma evidência inserida</td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </>}
        </>
    );
};

export default MultipleFilesComponent;
