import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from '../Login/Login';

export default function LoginRoute(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/gestao" element={<Login />} />
            </Routes>
        </BrowserRouter>
    )
}