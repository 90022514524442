import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import moment from 'moment';
import { setMaintenanceWarning, closeMaintenance, sendMail } from '../Api/Api';

export default function RelatModal({show,handleClose,userId}){

    const hide = () => {
        handleClose();
    }

    return (
        <>
            <Modal size="lg" show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Histórico de Tratativas
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card_table">
                        <div className="card-body">
                            <table cellPadding="5px" className='table table-hover text-center'>
                                <thead className='fw-bold'>
                                    <tr>
                                        <td>ID</td>
                                        <td>Descrição</td>
                                        <td>Operador</td>
                                        <td>Encarregado</td>
                                        <td>Data</td>
                                    </tr>
                                </thead>
                                <tbody className="modalNC">
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}